import React from "react"
import { graphql } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"

import BaseLayout from "../components/layout/base-layout"
import useImageQuery from "../hooks/use-image-query"

const Equestrian = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title, subtitle, caption, nameHeading, credentials },
    },
  } = data

  const gatsbyImageData = useImageQuery()
  const email = "betsy@wonderhorseequestrian.com"
  const gradient = {
    background:
      "radial-gradient(79.28% 126.42% at 90% 20.72%, #E5E5E5 0%, rgba(229, 229, 229, 0) 100%)",
  }
  const credentialList = credentials.split("|")

  return (
    <BaseLayout title="Home">
      <div style={gradient}>
        <div className="w-5/6 pt-3 md:py-20 mx-auto">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-5/12">
              <div className="w-full rounded-2xl my-3 md:my-0">
                <GatsbyImage
                  className="rounded-2xl filter drop-shadow-3xl"
                  alt="Betsy Kahl"
                  image={gatsbyImageData}
                />
              </div>
              <div className="italic mt-1 text-xs text-center font-thin">
                {caption}
              </div>
            </div>
            <div className="w-full md:w-7/12 py-8 md:py-2 md:px-20">
              <h1 className="mb-6 md:mb-8 text-center md:text-left lg:text-7xl">
                {title}
              </h1>
              <h4>{subtitle}</h4>
              <h2 className="md:mt-6">{nameHeading}</h2>
              <section className="mb-5">
                <ul className="italic text-xs md:text-sm text-gy-700 mb-1">
                  {credentialList.map(cred => (
                    <li>{cred}</li>
                  ))}
                </ul>
              </section>
              <section>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </section>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default Equestrian

export const query = graphql`
  query HomepageQuery {
    markdownRemark(frontmatter: { name: { eq: "home" } }) {
      html
      frontmatter {
        title
        subtitle
        caption
        nameHeading
        credentials
      }
    }
  }
`
