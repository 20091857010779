import { graphql, useStaticQuery } from "gatsby"

const useImageQuery = () => {
  const {
    file: {
      childImageSharp: { gatsbyImageData },
    },
  } = useStaticQuery(graphql`
    query ImageQuery {
      file(relativePath: { eq: "home/betsy_stu_clr.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return gatsbyImageData
}

export default useImageQuery
